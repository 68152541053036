import { graphql, Link } from "gatsby"
import React, { useCallback, useState } from "react"
import Layout from "../components/layout"
import { Seo } from "../components/seo"

const IconCalendar = (
  <svg
    aria-label="Schedule"
    viewBox="0 0 24 24"
    height="24"
    width="24"
    className="StyledIcon-sc-ofa7kd-0 jQvEgu"
  >
    <path
      fill="none"
      stroke="#000"
      strokeWidth="2"
      d="M1 23h22V4H1v19zM18 4V0v4zM6 4V0v4zM1 8.5h22H1zM6 14c.556-1.333 1.39-2 2.5-2 1.3 0 2 1 2 2s-1 2-2 3l-2 2v.5h5.405m5.08 1L17 12h-.5c-.5 1.5-2 2-2.743 2"
    ></path>
  </svg>
)

const resurseExterne = [
  {
    title: "Societatea Vânătorilor și Pescarilor din Republica Moldova",
    href: "https://svpm.md/",
  },
  {
    title: `Comunitatea "Vinatorul.md" pe Facebook`,
    href: "https://www.facebook.com/vinatorul",
  },
  {
    title: `Clubul Sportiv de trageri "Sniping Club"`,
    href: "https://snipingclub.md/",
  },
  {
    title: "Cartuș—Totul pentru vânătoare și turism",
    href: "http://cartus.md/",
  },
  {
    title: "Accesorii vânătoare (smart.md)",
    href: "https://www.smart.md/accesorii-vanatoare",
  },
]

export default function Home({ location, data }) {
  const [archiveVisible, setArchiveVisible] = useState(false)

  const toggleArchiveVisible = useCallback(
    event => {
      setArchiveVisible(!archiveVisible)
      event.preventDefault()
      return false
    },
    [archiveVisible]
  )

  return (
    <Layout location={location}>
      <ul>
        <li>
          <h3>
            <a href="/downloads/svpm_ordin_deschidere_2024_2025.pdf">
              SVPM: Ordinul cu privire la deschiderea sezonului de vânătoare
              2024&ndash;2025 (pdf)
            </a>
          </h3>
        </li>
        <li>
          <h3>
            <a
              style={{ textDecorationStyle: "dashed" }}
              href="#archive"
              onClick={toggleArchiveVisible}
            >
              Arhivă {archiveVisible ? "▲" : "▼"}
            </a>
          </h3>
          <ul
            id="archive"
            style={{
              display: archiveVisible ? "block" : "none",
            }}
          >
            <li>
              <h4>
                <a href="/downloads/svpm_ordin_deschidere_2023_2024.pdf">
                  SVPM: Ordinul cu privire la deschiderea sezonului de vânătoare
                  2023&ndash;2024 (pdf)
                </a>
              </h4>
              <h4>
                <a href="/downloads/svpm_ordin_deschidere_2022_2023.pdf">
                  SVPM: Ordinul cu privire la deschiderea sezonului de vânătoare
                  2022&ndash;2023 (pdf)
                </a>
              </h4>
            </li>
          </ul>
        </li>
      </ul>

      <hr />
      <h2>Pagini utile</h2>
      <ul className="index-page-list">
        <li>
          <Link to="/calendar/">
            <span style={{ marginRight: ".25em", lineHeight: 1 }}>
              {IconCalendar}
            </span>
            Calendarul vânătorului
          </Link>
        </li>
        {data.allMarkdownRemark.nodes.map((v, ix) => (
          <li key={ix}>
            <Link to={v.fields.slug}>{v.frontmatter.title}</Link>
          </li>
        ))}
      </ul>
      <hr />
      <h2>Resurse externe</h2>
      <ul>
        {resurseExterne.map(el => (
          <li key={el.href}>
            <a href={el.href} target="_blank" rel="noreferrer">
              {el.title}
            </a>
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export const Head = () => <Seo />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
`
